const SimplexNoise = require('simplex-noise');

// console.log(
//   '%c Hello from /scripts/script.js ',
//   'background: lemonchiffon; border: 1px solid #fff'
// );
// console.log(
//   '%c Welcome to tris-webpack-boilerplate! ',
//   'background: lemonchiffon; border: 1px solid #fff'
// );
// console.log(
//   '%c Coded by: https://tris.codes 💠',
//   'background: lavenderblush; border: 1px solid #000; padding: 4px; padding-top: 10px; padding-bottom: 8px;'
// );

// const { PI, cos, sin, abs, sqrt, pow, round, random, atan2 } = Math;
const { PI, random, cos, sin, abs } = Math;
// const HALF_PI = 0.5 * PI;
const TAU = 2 * PI;
// const TO_RAD = PI / 180;
// const floor = n => n | 0;
const rand = n => n * random();
// const randIn = (min, max) => rand(max - min) + min;
const randRange = n => n - rand(2 * n);
// const fadeIn = (t, m) => t / m;
// const fadeOut = (t, m) => (m - t) / m;
const fadeInOut = (t, m) => {
  const hm = 0.5 * m;
  return abs(((t + hm) % m) - hm) / hm;
};
// const dist = (x1, y1, x2, y2) => sqrt(pow(x2 - x1, 2) + pow(y2 - y1, 2));
// const angle = (x1, y1, x2, y2) => atan2(y2 - y1, x2 - x1);
const lerp = (n1, n2, speed) => (1 - speed) * n1 + speed * n2;

const particleCount = 200;
const particlePropCount = 100;
const particlePropsLength = particleCount * particlePropCount;
const rangeY = 100;
const baseTTL = 50;
const rangeTTL = 600;
const baseSpeed = 0.1;
const rangeSpeed = 2;
const baseRadius = 1;
const rangeRadius = 4;
const baseHue = 90;
const rangeHue = 60;
const noiseSteps = 8;
const xOff = 0.00125;
const yOff = 0.00125;
const zOff = 0.0005;
const backgroundColor = 'hsla(260,40%,5%,1)';

let container;
let canvas;
let ctx;
let center;
// let gradient;
let tick;
let simplex;
let particleProps;
// let positions;
// let velocities;
// let lifeSpans;
// let speeds;
// let sizes;
// let hues;

function initParticle(i) {
  const x = rand(canvas.a.width);
  const y = center[1] + randRange(rangeY);
  const vx = 0;
  const vy = 0;
  const life = 0;
  const ttl = baseTTL + rand(rangeTTL);
  const speed = baseSpeed + rand(rangeSpeed);
  const radius = baseRadius + rand(rangeRadius);
  const hue = baseHue + rand(rangeHue);

  particleProps.set([x, y, vx, vy, life, ttl, speed, radius, hue], i);
}

function initParticles() {
  tick = 0;
  simplex = new SimplexNoise();
  particleProps = new Float32Array(particlePropsLength);

  let i;

  for (i = 0; i < particlePropsLength; i += particlePropCount) {
    initParticle(i);
  }
}

function drawParticle(x, y, x2, y2, life, ttl, radius, hue) {
  ctx.a.save();
  ctx.a.lineCap = 'round';
  ctx.a.lineWidth = radius;
  ctx.a.strokeStyle = `hsla(${hue},100%,90%,${fadeInOut(life, ttl)})`;
  ctx.a.beginPath();
  ctx.a.moveTo(x, y);
  ctx.a.lineTo(x2, y2);
  ctx.a.stroke();
  ctx.a.closePath();
  ctx.a.restore();
}

function checkBounds(x, y) {
  return x > canvas.a.width || x < 0 || y > canvas.a.height || y < 0;
}

function updateParticle(i) {
  const i2 = 1 + i;
  const i3 = 2 + i;
  const i4 = 3 + i;
  const i5 = 4 + i;
  const i6 = 5 + i;
  const i7 = 6 + i;
  const i8 = 7 + i;
  const i9 = 8 + i;

  const x = particleProps[i];
  const y = particleProps[i2];
  const n = simplex.noise3D(x * xOff, y * yOff, tick * zOff) * noiseSteps * TAU;
  const vx = lerp(particleProps[i3], cos(n), 0.5);
  const vy = lerp(particleProps[i4], sin(n), 0.5);
  let life = particleProps[i5];
  const ttl = particleProps[i6];
  const speed = particleProps[i7];
  const x2 = x + vx * speed;
  const y2 = y + vy * speed;
  const radius = particleProps[i8];
  const hue = particleProps[i9];

  drawParticle(x, y, x2, y2, life, ttl, radius, hue);

  // eslint-disable-next-line no-plusplus
  life++;

  particleProps[i] = x2;
  particleProps[i2] = y2;
  particleProps[i3] = vx;
  particleProps[i4] = vy;
  particleProps[i5] = life;

  // eslint-disable-next-line no-unused-expressions
  (checkBounds(x, y) || life > ttl) && initParticle(i);
}

function drawParticles() {
  let i;

  for (i = 0; i < particlePropsLength; i += particlePropCount) {
    updateParticle(i);
  }
}

function createCanvas(selectorName) {
  container = document.querySelector(selectorName);
  canvas = {
    a: document.createElement('canvas'),
    b: document.createElement('canvas')
  };
  canvas.b.style = `
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	`;
  container.appendChild(canvas.b);
  ctx = {
    a: canvas.a.getContext('2d'),
    b: canvas.b.getContext('2d')
  };
  center = [];
}

function resize() {
  const { innerWidth, innerHeight } = window;

  canvas.a.width = innerWidth;
  canvas.a.height = innerHeight;

  ctx.a.drawImage(canvas.b, 0, 0);

  canvas.b.width = innerWidth;
  canvas.b.height = innerHeight;

  ctx.b.drawImage(canvas.a, 0, 0);

  center[0] = 0.5 * canvas.a.width;
  center[1] = 0.5 * canvas.a.height;
}

function renderGlow() {
  ctx.b.save();
  ctx.b.filter = 'blur(8px) brightness(200%)';
  ctx.b.globalCompositeOperation = 'lighter';
  ctx.b.drawImage(canvas.a, 0, 0);
  ctx.b.restore();

  ctx.b.save();
  ctx.b.filter = 'blur(4px) brightness(200%)';
  ctx.b.globalCompositeOperation = 'lighter';
  ctx.b.drawImage(canvas.a, 0, 0);
  ctx.b.restore();
}

function renderToScreen() {
  ctx.b.save();
  ctx.b.globalCompositeOperation = 'lighter';
  ctx.b.drawImage(canvas.a, 0, 0);
  ctx.b.restore();
}

function draw() {
  // eslint-disable-next-line no-plusplus
  tick++;

  ctx.a.clearRect(0, 0, canvas.a.width, canvas.a.height);

  ctx.b.fillStyle = backgroundColor;
  ctx.b.fillRect(0, 0, canvas.a.width, canvas.a.height);

  drawParticles();
  renderGlow();
  renderToScreen();

  window.requestAnimationFrame(draw);
}

function setup() {
  createCanvas('.content--canvas');
  resize();
  initParticles();
  draw();
}

window.addEventListener('load', setup);
window.addEventListener('resize', resize);
